$(document).ready(function () {
    $(document).on('click', '.js__gtm_link', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const tileInfoForGTM = $(this).closest('.js_good_tile');
        const gtmItem = {
            'id': tileInfoForGTM.attr('data-id'),
            'gtm_item_list': tileInfoForGTM.attr('data-gtm_item_list'),
            'gtm_item': tileInfoForGTM.attr('data-gtm_item')
        }

        localStorage.setItem('gtm_info', JSON.stringify(gtmItem));
        window.location.href = $(this).attr('href');
    });
})