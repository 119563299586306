const videoPosterHide = function () {
    $('.video').on('click', function () {
        if ($(this).children('.js_poster').length != 0) {
            $(this).children('.js_poster').addClass('js_hide');
            if ($(this).children('.js_video_src').length != 0) {
                $(this).children('.js_video_src')[0].play();
            }
        }
    });

    $('.js__good_slider_video').on('click', function () {
        const typeVideo = $('data-video');
        if ($(this).children('.js_poster').length != 0) {

            if (typeVideo == 'video') {
                $(this).children('.js_poster').addClass('js_hide');
            }

            if ($(this).children('.js_video_src').length != 0) {
                $(this).children('.js_video_src')[0].play();
            }
        }
    });

    $('.video_slider[data-video-youtube]').click(function () {
        const attrVideo = $(this).attr('data-video-youtube');
        $('#modal_video iframe').attr('src', attrVideo);
        $('#modal_video').addClass('js_active');
    });

    $('#modal_video .js_modal_overlay').click(function () {
        $(this).closest('#modal_video').find('iframe').attr('src', '');
        $('.modal_video.active').removeClass('active')
    });

}

window.videoPosterHide = videoPosterHide;