$(document).ready(function () {
    // $('.menu_navigate').each(function () {
    //     const href = $(this).attr('href');
    //     if (href != "") {
    //         var url = new URL(href);
    //         var path = url.pathname;
    //         const linkPage = window.location.pathname;


    //         console.log(path);

    //         if (path == linkPage) {
    //             $(this).addClass('active');
    //         } else {
    //             let hrafArr = path.split('/').filter(function (element) {
    //                 return element.trim() !== '';
    //             });
    //             delete hrafArr[0];
    //             let linkPageArr = linkPage.split('/').filter(function (element) {
    //                 return element.trim() !== '';
    //             });
    //             delete linkPageArr[0];

    //             var hasIntersection = linkPageArr.some(function (element) {
    //                 return hrafArr.indexOf(element) !== -1;
    //             });

    //             if (hasIntersection) {
    //                 $(this).addClass('active');
    //             }
    //         }
    //     }
    // })
})

// const getHeightMenu = function () {
//     console.log(12);
//     if ($('.good-page-top').length != 1) {
//         $('.js_main_wrap').css('padding-top', `${$('.js_header_mobile').outerHeight()}px`);
//         $('.js_main_wrap').css('padding-top', `${$('.js_header_desctop').outerHeight()}px`);
//     } else {
//         $('.js_main_wrap').css('padding-top', `${$('.js_header_mobile').outerHeight()}px`);
//         $('.js_main_wrap').css('padding-top', `${$('.js_header_desctop').outerHeight()}px`);

//         $(document).scroll(function () {
//             let heightScrollBefore = $('.good-page-tab-main').offset().top;
//             if ($(window).scrollTop() < heightScrollBefore || $(window).width() < 768) {
//                 $('.js_header_desctop, .js_header_mobile').css('display', 'block');
//             } else {
//                 $('.js_header_desctop, .js_header_mobile').slideUp("slow");
//             }
//         });
//     }
// }

const menuDesctop = function () {
    $('.js_menu_desctop').on('click', function () {

        $('.js-block-menu').removeClass('js-active-child')
        $(this).toggleClass('open-menu');
        $(this).closest('.js_header_bottom').find('.nav').css('top', ``);
        $(this).closest('.js_header_bottom').find('.nav').toggleClass('js_active');
        $('body').toggleClass('overflow-hidden');
        let heightMenuBlock = $(this).closest('.js_header_desctop').outerHeight();

        $(this).closest('.js_header_bottom').find('.nav .big-menu-block').css('top', `${heightMenuBlock}px`);
    });

    $('.js-open-child-menu').on('click', function (e) {
        e.preventDefault();
        $(this).closest('li').children('.js-block-menu').addClass('js-active-child');
    });

    $('.color_menu').on('click', function () {
        $(this).closest('.js-active-child').removeClass('js-active-child');
    });
}

const menuMob = function () {
    $('.js-open-child-mob-menu').on('click', function (e) {
        e.preventDefault();
        $(this).closest('li').children('.js-block-menu').addClass('js-active-mob');
    });

    $('.mobile-back-button .color_menu').on('click', function () {
        $(this).closest('.js-active-mob').removeClass('js-active-mob');
    });

    $('.js_menu_btn, .menu-mobile-close').on('click', function () {
        $('.js_menu_mobile').toggleClass('open');
        $('body').toggleClass('blur');
        $('.js-block-menu').removeClass('js-active-mob');
    });
}

$(window).resize(function () {
    if ($(window).width() < 1199) {
        menuDesctop();
    }
});

$(document).ready(function () {
    if ($(window).width() < 1199) {
        menuDesctop();
    }
    menuMob();
});

$(window).on('load', function () {
    // getHeightMenu();
});