$(window).on('load', function () {
    $('.addition-category-content').each(function () {
        const count = $(this).attr('data-count');

        $(this).slick({
            slidesToShow: count,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [{
                breakpoint: 1366,
                settings: {
                    slidesToShow: count >= 4 ? 4 : count,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }]
        });
    });

    $('.addition-review-content').each(function () {
        const count = $(this).attr('data-count');


        $(this).slick({
            slidesToShow: count,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: count >= 2 ? 2 : count,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        customPaging: function (slider, i) {
                            return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                        },
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                },
            ]
        });
    });

    $('.addition-review-content-big').each(function () {
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [{
                breakpoint: 768,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    });

    $('.addition-gallery-slider').each(function () {
        var element = $(this).get(0); // Получить первый элемент из набора элементов
        lightGallery(element, {
            selector: '.js__addit_lightGallery',
            plugins: [lgZoom, lgThumbnail],
            speed: 500,
            licenseKey: 'your_license_key'
        });

        $(this).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [{
                breakpoint: 1550,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    });

    $('.addition-blog-content').each(function () {
        const count = $(this).attr('data-count');

        $(this).slick({
            slidesToShow: count,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [{
                breakpoint: 1366,
                settings: {
                    slidesToShow: count >= 4 ? 4 : count,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: count >= 3 ? 3 : count,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }]
        });
    });

    $('.addition-slider-side-js').each(function () {
        $(this).slick({
            dots: true,
            customPaging: function (slider, i) {
                return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
            },
            arrows: false,
            fade: true,
            rows: 0,
        });
    });

    $(".addition-goods-slick").each(function () {

        const count = $(this).attr('data-count');

        $(this).slick({
            slidesToShow: count,
            slidesToScroll: 1,
            prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
            nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
            rows: 0,
            responsive: [{
                breakpoint: 1366,
                settings: {
                    slidesToShow: count >= 4 ? 4 : count,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 1024,
                settings: {
                    slidesToShow: count >= 3 ? 3 : count,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 450,
                settings: {
                    dots: true,
                    customPaging: function (slider, i) {
                        return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                    },
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    });


    $(document).on('click', '.arrow-next, .arrow-prev', function () {
        lazyLoad();
    });

    $(document).on('click', '.swiper_dots', function () {
        lazyLoad();
    });

    $('.addition-category-content, .addition-review-content, .addition-gallery-slider, .addition-blog-content, .addition-slider-side-js, .addition-goods-slick').on('swipe', function () {
        lazyLoad();
    });

});