$(document).ready(function () {
    $(document).on('click', '.js_button_faqs', function () {
        if ($(this).closest('.faqs_item').hasClass('active')) {
            $(this).closest('.faqs_item').removeClass('active');
            $(this).closest('.faqs_item').find('.block_faqs').slideUp();
        } else {
            $('.js_button_faqs').closest('.faqs_item').removeClass('active');
            $('.block_faqs').slideUp('active');

            $(this).closest('.faqs_item').addClass('active');
            $(this).closest('.faqs_item').find('.block_faqs').slideDown();
        }
    });

    if ($('.js_button_faqs').length > 0) {
        $('.js_button_faqs').first().trigger('click');
    }
})