const setDashesForBrands = function () {
    $('.addition-brand-content').each(function () {
        let count = $(this).attr('data-count');

        $(this).find('.addition-brand-item').each(function (i) {
            if ((i + 1) % count == 0) {
                $(this).find('a').css('border-right', 'none');
            }
        })
    });
}

const getHeightBlogAddition = function () {
    $('.adiition-height-flating').each(function () {
        let minHeight = 0;

        $(this).find('.addition-name').each(function () {
            if (minHeight < $(this).outerHeight()) {
                minHeight = $(this).outerHeight();
            }
        });

        minHeight != 0
            ? $(this).find('.addition-name').css('min-height', `${minHeight}px`)
            : null;
    });
}

const attrClick = function () {
    $(document).on("click", ".js_btn_click", (function (e) {
        e.stopPropagation(), e.preventDefault();
        var s = $(this).attr("data-block");
        $(this).hasClass("js_active")
            ? ($(this).removeClass("js_active"), $(".js_block_click[data-block=" + s + "]").removeClass("js_active"), $(".js_block_click_slide[data-block=" + s + "]").slideUp(0, (function () {
                $(this).removeClass("js_active")
            })))
            : ($(".js_btn_click").removeClass("js_active"), $(".js_block_click").removeClass("js_active"), $(".js_block_click_slide")
                .slideUp(400, (function () { $(this).removeClass("js_active") })),
                $(this).addClass("js_active"), $(".js_block_click[data-block=" + s + "]").addClass("js_active"), $(".js_block_click_slide[data-block=" + s + "]").slideDown(0, (function () {
                    $(this).addClass("js_active")
                })))

        let widthHeight = $(window).width();
        let widthLeft = $(this).closest('.js_header_cart').children('.js_block_click_slide')
            .offset().left + $(this).closest('.js_header_cart')
                .children('.js_block_click_slide').outerWidth(true);

        (widthLeft - widthHeight > 0)
            ? $(this).closest('.js_header_cart')
                .children('.js_block_click_slide').css('left', `calc(50% - ${widthLeft - widthHeight + 15}px)`)
            : $(this).closest('.js_header_cart')
                .children('.js_block_click_slide').css('left', '50%');
    }));

    $(document).on("click", ".js_btn_click_close", (function (e) {
        e.stopPropagation(), e.preventDefault();
        var s = $(this).attr("data-block");
        $(".js_btn_click[data-block=" + s + "]").removeClass("js_active"), $(".js_block_click[data-block=" + s + "]").removeClass("js_active"), $(".js_block_click_slide[data-block=" + s + "]").slideUp(400, (function () {
            $(this).removeClass("js_active")
        }))
    }))
}


$(window).resize(function () {
    $('.addition-name').css('min-height', '');
    getHeightBlogAddition();

    if ($(window).width() < 1200) {
        $('.addition-brand-content a').css('border-right', '')
    } else {
        setDashesForBrands();
    }
});

$(document).ready(function () {
    if ($(window).width() > 1200) {
        setDashesForBrands();
    }

    attrClick();
});

$(window).on('load', function () {
    getHeightBlogAddition();
});