$(document).ready(function() {
    specialistTextHeight()
    $(window).on('resize', function() {
        specialistTextHeight()
    })
})

function specialistTextHeight() {
    if ($(window).outerWidth() > 567) {
        let allItems = $('.js_specialist__item').length
        let items = 4
        let lines = 1
        let heightArr = []
        $('.js_specialist__name').css('height', '')
        if ($(window).outerWidth() > 767 && $(window).outerWidth() < 1024) {
            items = 3
        } else if ($(window).outerWidth() > 567 && $(window).outerWidth() < 768) {
            items = 2
        }
        if (allItems <= items) {
            lines = 1
        } else {
            lines = Math.ceil(allItems / items)
        }
        if (lines === 1) {
            heightArr[0] = 0
            $('.js_specialist__item').each((i, el) => {
                heightArr[0] = heightArr[0] < $(el).find('.js_specialist__name').outerHeight() ? $(el).find('.js_specialist__name').outerHeight() : heightArr[0]
            })
            $('.js_specialist__item').each((i, el) => {
                $(el).find('.js_specialist__name').css('height', heightArr[0])
            })
        } else {
            for ( let k = 0 ; k < lines ; k++ ) {
                $('.js_specialist__item').each((i, el) => {
                    if (!heightArr[k]) {
                        heightArr[k] = $(el).find('.js_specialist__name').innerHeight()
                    } else {
                        if (i >= k * items && i <= k * items + items - 1) {
                            let nameHeight = $(el).find('.js_specialist__name').innerHeight()
                            heightArr[k] = heightArr[k] < nameHeight ? nameHeight : heightArr[k]
                        }
                    }
                })
            }
            console.log(heightArr)
        }
        for ( let k = 0 ; k < items ; k++ ) {
            $('.js_specialist__item').each((i, el) => {
                if (i >= k * items && i <= k * items + items - 1) {
                    $(el).find('.js_specialist__name').css('height', heightArr[k])
                }
            })
        }
        // console.log('all', allItems)
        // console.log('items', items)
        // console.log('lines', lines)
    } else {
        $('.js_specialist__name').removeAttr('style')
    }
}